import React from "react";

export default function Index() {
    return (
        <div className="flex items-center justify-center py-12">
            <svg className="animate-spin" width={228} height={228} viewBox="0 0 228 228" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M228 114C228 88.9047 219.719 64.5106 204.442 44.6012C189.165 24.6918 167.746 10.3796 143.505 3.88446C119.265 -2.61069 93.5591 -0.925823 70.3741 8.67773C47.1891 18.2813 27.8208 35.2668 15.2731 57C2.72546 78.7332 -2.30031 103.999 0.975285 128.88C4.25088 153.761 15.6448 176.865 33.3898 194.61C51.1349 212.355 74.2394 223.749 99.12 227.025C124.001 230.3 149.267 225.275 171 212.727L161.555 196.367C143.423 206.836 122.343 211.028 101.586 208.296C80.828 205.563 61.5521 196.057 46.7475 181.252C31.943 166.448 22.4371 147.172 19.7043 126.414C16.9715 105.657 21.1645 84.5771 31.6329 66.4453C42.1013 48.3135 58.2601 34.1426 77.6032 26.1304C96.9463 18.1182 118.393 16.7126 138.616 22.1314C158.84 27.5503 176.71 39.4908 189.455 56.1011C202.201 72.7114 209.109 93.0632 209.109 114H228Z"
                    fill="#DBEAFE"
                />
                <path
                    d="M49 113.5C49 127.919 53.7577 141.935 62.5354 153.374C71.313 164.813 83.6199 173.036 97.5473 176.768C111.475 180.5 126.245 179.532 139.566 174.014C152.887 168.496 164.015 158.737 171.225 146.25C178.434 133.763 181.322 119.246 179.44 104.951C177.558 90.6551 171.011 77.3801 160.815 67.1845C150.62 56.9889 137.345 50.4424 123.049 48.5604C108.754 46.6783 94.237 49.5659 81.75 56.7753L87.1769 66.175C97.5948 60.1603 109.706 57.7512 121.633 59.3214C133.559 60.8915 144.635 66.3532 153.141 74.8593C161.647 83.3655 167.108 94.4407 168.679 106.367C170.249 118.294 167.84 130.405 161.825 140.823C155.81 151.241 146.526 159.383 135.412 163.986C124.298 168.59 111.976 169.398 100.357 166.284C88.7369 163.171 78.4694 156.31 71.1463 146.766C63.8232 137.223 59.8538 125.529 59.8538 113.5L49 113.5Z"
                    fill="#1D4ED8"
                />
            </svg>
        </div>
    );
}
